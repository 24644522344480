import styled from 'styled-components';
import { Button } from '@mui/material';
import { ReactComponent as Logo } from '../../assets/LogoLogin.svg';
import { NavLink } from 'react-router-dom';

export const LoginContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.loginBGColor};
`;

export const LogoContainer = styled.div`
  margin-bottom: 64px;
  width: 100%;
`;

export const StyledLogo = styled(Logo)`
  width: 100%;
  transform: scale(1);
  @media ${(props) => props.theme.device.tablet} {
    transform: none;
  }
`;

export const CenteredSquare = styled.div`
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${(props) => props.theme.loginBoxBGColor};
  border-radius: 10px;
  padding: 40px;
  border: ${(props) => props.theme.loginBorder};
  @media ${(props) => props.theme.device.tablet} {
    width: 100%;
    padding: 0px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin: 0 auto;
  @media ${(props) => props.theme.device.tablet} {
    width: 90%;
  }
`;

export const Label = styled.label`
  font-size: 18px;
  margin: 10px 0;
`;

export const LoginTitleContainer = styled.div`
  color: ${(props) => props.theme.loginColor};
  font-weight: bold;
  font-size: 32px;
  line-height: 150%;
  text-align: center;
  @media ${(props) => props.theme.device.tablet} {
    font-size: 20px;
    line-height: 27px;
  }
`;

export const LoginTermosDeUsoContainer = styled.div`
  color: ${(props) => props.theme.loginTermosDeUsoColor};
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  @media ${(props) => props.theme.device.tablet} {
    font-size: 12px;
  }
`;

export const LoginTermosDeUsoLink = styled(NavLink)`
  cursor: pointer;
  color: ${(props) => props.theme.loginButtonBgc};
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  @media ${(props) => props.theme.device.tablet} {
    font-size: 12px;
  }
`;

export const LoginErrorContainer = styled.div`
  color: ${(props) => props.theme.loginErrorColor};
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  text-decoration: underline;
  @media ${(props) => props.theme.device.tablet} {
    font-size: 14px;
  }
`;

export const LoginEsqueciContainer = styled.div`
  text-align: center;
  text-decoration: underline;
`;

export const LoginEsqueciLink = styled(NavLink)`
  cursor: pointer;
  color: ${(props) => props.theme.loginButtonBgc};
  font-size: 16px;
  line-height: 150%;
  text-decoration: inherit;
  @media ${(props) => props.theme.device.tablet} {
    font-size: 14px;
  }
`;

export const SubmitButton = styled(Button)`
  background: ${(props) => props.theme.loginButtonBgc};
  color: ${(props) => props.theme.loginButtonColor};
  font-size: 14px;
  font-weight: bold;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  text-transform: uppercase;
  &:hover {
    background-color: ${(props) => props.theme.loginButtonBgc}a1;
  }
`;

export const EsqueciMinhaSenhaContent = styled.div`
  font-size: 18px;
  color: ${(props) => props.theme.loginColor};
  text-align: center;
`;

export const EsqueciMinhaSenhaVoltar = styled(NavLink)`
  font-size: 18px;
  color: ${(props) => props.theme.loginColor};
  text-align: center;
`;

export const ContainerMudarSenha = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.fontColor};
  text-align: center;
`;

export const MudarSenhaTitle = styled.div`
  color: ${({ theme }) => theme.fontColor};
  font-weight: bold;
  font-size: 32px;
  line-height: 150%;
  text-align: center;
  @media ${(props) => props.theme.device.tablet} {
    font-size: 20px;
    line-height: 27px;
  }
`;

export const SenhaFracaContainer = styled.div`
  color: ${(props) => props.theme.loginErrorColor};
  font-size: 14px;
  line-height: 150%;
  text-align: left;
  @media ${(props) => props.theme.device.tablet} {
    font-size: 14px;
  }
  div {
    padding: 0 8px;
  }
`;

export const ButtonTrocarSenha = styled(Button)`
  background-color: ${(props) => props.theme.backgroundColorMain};
  color: ${(props) => props.theme.loginColor};
  font-size: 14px;
  font-weight: bold;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  text-transform: uppercase;
  &:hover {
    background-color: ${(props) => props.theme.backgroundColorMain}cc;
  }
`;
